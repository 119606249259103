<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
  >
    <v-card
      shaped
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-cloud-upload
        </v-icon> Upload Content
      </h2>
      <br>
      <div>
        <p
          class="p-style"
        >
          Drag And Drop Your Files Here To Upload<v-icon color="#2E7D32">
            mdi-checkbox-marked-circle
          </v-icon>
        </p>
      </div>
      <v-col
        cols="12"
      >
        <div
          class="outer-div"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <input
            id="assetsFieldHandle"
            ref="file"
            type="file"
            multiple
            name="fields[assetsFieldHandle][]"
            class="w-px h-px input-file-style"
            accept=".mp4,.gif,.jpg,.jpeg,.png"
            @change="onChange"
          >

          <label
            for="assetsFieldHandle"
            class="block cursor-pointer"
          >
            <div class="text-div">
              Click to Upload
              <v-spacer />
              <v-icon
                size="40"
                color="gray"
              >
                mdi-cloud-upload-outline
              </v-icon>
            </div>
          </label>
          <v-chip
            v-for="(file, index) in campaignFile"
            :key="index"
            class="ma-2"
            dark
            color="#E0E0E0"
            text-color="black"
            close
            @click:close="remove(index)"
          >
            {{ file.name }}
          </v-chip>
        </div>
      </v-col>
      <br>
      <v-card-actions>
        <v-btn
          class="mt-2"
          color="red darken-3"
          @click="showModal = false"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          class="mt-2"
          color="#FF3700"
          @click="uploadFiles()"
        >
          Upload
        </v-btn>
      </v-card-actions>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';

  export default {
    name: 'UploadOnEdit',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        campaignFile: [],
        rotationInterval: 5,
        loading: false,
      };
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      onChange () {
        const fi = document.getElementById('assetsFieldHandle');
        if (fi.files.length > 0) {
          for (var i = 0; i < fi.files.length; i++) {
            const fsize = fi.files.item(i).size;
            const file = Math.round((fsize / 1024));
            if (file >= 30096) {
              const name = fi.files.item(i).name;
              this.$store.dispatch('alert/onAlert', {
                message: `File ${name} is too Big, Please select a file of less than 30 Mb.`,
                type: Constants.ALERT_TYPE_ERROR,
              }, { root: true });
            } else {
              this.campaignFile.push(fi.files[i]);
            }
          }
        }
      },
      remove (i) {
        this.campaignFile.splice(i, 1);
      },
      dragover (event) {
        event.preventDefault();
        if (!event.currentTarget.classList.contains('bg-green-300')) {
          event.currentTarget.classList.add('bg-green-300');
        }
      },
      dragleave (event) {
        event.currentTarget.classList.remove('bg-green-300');
      },
      drop (event) {
        event.preventDefault();
        this.$refs.file.files = event.dataTransfer.files;
        this.onChange();
        event.currentTarget.classList.remove('bg-green-300');
      },
      async uploadFiles () {
        this.loading = true;
        for (var i = 0; i < this.campaignFile.length; i++) {
          const formData = new FormData();
          const digiBoardTimeSlots = [];
          formData.append('content_file', this.campaignFile[i]);
          formData.append('category_type', 'banner');
          await this.$store.dispatch('campaigns/uploadCampaignFiles', {
            formData: formData,
            digiBoardTimeSlots: digiBoardTimeSlots,
          }).catch(() => {
            this.loading = false;
          });
        }
        this.loading = false;
        this.showModal = false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.p-style {
  font-size: 18px;
  font-weight: bold;
  color: #37474F;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  text-align: center;
}
</style>
<style lang="css" scoped>
[v-cloak] {
  display: none !important;
}
.bg-green-300 {
  background-color: #66BB6A;
}
.input-file-style {
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.outer-div {
  min-height: 120px;
  color: #000;
  border: 3px solid #BDBDBD;
  border-style: dashed;
  border-radius: 20 px;
  border-collapse: separate;
  margin-left: 10px;
  margin-right: 10px;
}
.text-div {
  margin-top: 5px;
  text-align: center;
  font-size: 20px;
  color: #FF3700;
  font-family: 'Times New Roman', Times, serif;
}
</style>
