<template>
  <section>
    <v-dialog
      v-model="showModal"
      max-width="700px"
    >
      <v-card
        shaped
        color="#ECEFF1"
      >
        <br>
        <h2 class="modal-title-style">
          <v-icon class="modal-icon-style">
            mdi-timer-edit-outline
          </v-icon> Assign Time Slots
        </h2>
        <v-card-text>
          <v-form>
            <v-container>
              <div
                v-for="(item, i) in boardTimeSlotsForLinking"
                :key="i"
              >
                <span class="board-details">
                  {{ item.digi_board.external_id }} - {{ item.digi_board.location }} <v-icon
                    left
                    color="#2E7D32"
                  >
                    mdi-checkbox-marked-circle
                  </v-icon>
                </span>
                <div
                  v-for="(slot, index) in item.time_slots"
                  :key="index"
                >
                  <div class="checkbox-div">
                    <input
                      type="checkbox"
                      :checked="isTimeSlotAlreadyChecked(slot.digi_board_time_slot_id)"
                      :disabled="selectOneTimeSlotPerBoard(item.digi_board.external_id, slot)"
                      @change="selectBoardTimeSlot(item.digi_board.external_id, slot, $event)"
                    >
                    <span class="my-checkbox"> {{ slot.name }} </span>
                    <span class="plan-dates">[ {{ slot.start | convertToLocal }} - {{ slot.end | convertToLocal }} ]</span>
                    <span
                      v-if="slot.running_status === true"
                      class="running-status"
                    >
                      Used*
                    </span>
                  </div>
                </div>
                <hr>
                <br>
              </div>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="showModal = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            @click="confirm()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
      <centre-spinner
        :loading="loading"
      />
    </v-dialog>
  </section>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import Constants from 'src/constants';
import moment from 'moment-timezone';

export default {
  name: 'SelectDigiBoardTimeSlots',
  components: {
    'centre-spinner': spinner,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMM YYYY hh:mm A');
    },
  },
  props: {
    contentDetails: {
      type: Object,
      required: true,
    },
    campaignFormDetails: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      showModal: true,
      loading: false,
      selectedBoardTimeSlots: [],
    };
  },
  computed: {
    boardTimeSlotsForLinking () {
      return this.$store.getters['digiboards/getSelectedOrgBoardsForContentLinking'];
    },
    campaignFiles () {
      return this.$store.getters['campaigns/getCampaignFiles'];
    },
    slotUpdatedCampaignContents () {
      return this.$store.getters['campaigns/getSlotUpdatedCampaignContents'];
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
        this.selectedBoardTimeSlots = [];
      }
    },
  },
  async mounted () {
   await this.getCurrentContentLinkedSlots();
  },
  methods: {
    getCurrentContentLinkedSlots () {
      const campaignContent = this.slotUpdatedCampaignContents.filter(item => item.formData.content.unique_file_name === this.contentDetails.content.unique_file_name);
      const digiBoardTimeSlots = campaignContent[0].digiBoardTimeSlots;
      for (const item of this.boardTimeSlotsForLinking) {
        for (const x of digiBoardTimeSlots) {
          if (item.time_slots.some(y => item.digi_board.external_id === x.boardExternalId && y.digi_board_time_slot_id === x.boardTimeSlotId)) {
            this.selectedBoardTimeSlots.push(x);
          }
        }
      }
    },
    isTimeSlotAlreadyChecked (timeSlotId) {
     if (this.selectedBoardTimeSlots.some(item => item.boardTimeSlotId === timeSlotId)) {
        return true;
      }
      return false;
    },
    async confirm () {
      const index = this.slotUpdatedCampaignContents.findIndex(item => item.formData.content.unique_file_name === this.contentDetails.content.unique_file_name);
      await this.$store.dispatch('campaigns/updateCampaignContentBoardSlot', {
        slotLinkedContent: {
          formData: this.contentDetails,
          digiBoardTimeSlots: this.selectedBoardTimeSlots,
        },
        currentContentIndex: index,
      });
      this.selectedBoardTimeSlots = [];
      this.showModal = false;
    },
    selectOneTimeSlotPerBoard (boardExternalId, boardTimeSlot) {
      if ((this.selectedBoardTimeSlots.some(item => item.boardExternalId === boardExternalId && item.boardTimeSlotId !== boardTimeSlot.digi_board_time_slot_id))) {
        return true;
      }
      return false;
    },
    selectBoardTimeSlot (boardExternalId, boardTimeSlot, $event) {
      if (this.selectedBoardTimeSlots.some(item => item.boardTimeSlotId === boardTimeSlot.digi_board_time_slot_id)) {
        const index = this.selectedBoardTimeSlots.findIndex(item => item.boardTimeSlotId === boardTimeSlot.digi_board_time_slot_id);
        this.selectedBoardTimeSlots.splice(index, 1);
        const i = this.slotUpdatedCampaignContents.findIndex(item => item.formData.content.unique_file_name === this.contentDetails.content.unique_file_name);
        this.$store.dispatch('campaigns/updateCampaignContentBoardSlot', {
          slotLinkedContent: {
            formData: this.contentDetails,
            digiBoardTimeSlots: this.selectedBoardTimeSlots,
          },
          currentContentIndex: i,
        });
        return;
      }
      const digiBoardTimeSlotIds = [];
      for (const item of this.contentDetails.digi_board_time_slots) {
        digiBoardTimeSlotIds.push(item.id);
      }
      if (!digiBoardTimeSlotIds.includes(boardTimeSlot.digi_board_time_slot_id) && boardTimeSlot.running_status === true) {
        $event.target.checked = false;
        this.$store.dispatch('alert/onAlert', {
          message: 'This slot is already in use, please remove the content before linking a new one.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      if (boardTimeSlot.subscription_status === 'expired') {
        $event.target.checked = false;
        this.$store.dispatch('alert/onAlert', {
          message: 'Slot expired, please choose a different slot from the list for linking.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      if (this.contentDetails.content.content_type === 'video' && (this.contentDetails.content.duration / 1000) > boardTimeSlot.duration) {
        $event.target.checked = false;
        this.$store.dispatch('alert/onAlert', {
          message: 'Slot selected is less than your video duration, please choose a different one.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      if (this.convertToGmt(this.campaignFormDetails.start) < boardTimeSlot.start || this.convertToGmt(this.campaignFormDetails.end) > boardTimeSlot.end) {
        $event.target.checked = false;
        this.$store.dispatch('alert/onAlert', {
          message: 'Selected slot plan is not within content active dates, please choose a different one.',
          type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      for (const item of this.slotUpdatedCampaignContents) {
        if (item.digiBoardTimeSlots.some(x => x.boardTimeSlotId === boardTimeSlot.digi_board_time_slot_id)) {
          $event.target.checked = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Slot already selected for the other content, please choose a different one.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
      }
      for (const item of this.campaignFiles) {
        if (item.digiBoardTimeSlots.some(x => x.boardTimeSlotId === boardTimeSlot.digi_board_time_slot_id)) {
          $event.target.checked = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Slot already selected for the other content, please choose a different one.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
      }
      this.selectedBoardTimeSlots.push({
        boardExternalId: boardExternalId,
        boardTimeSlotId: boardTimeSlot.digi_board_time_slot_id,
        start: boardTimeSlot.start,
        end: boardTimeSlot.end,
      });
    },
    convertToGmt (date) {
      return moment(date).utc().format();
    },
  },
};
</script>
<style scoped>
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.modal-title-style {
  color: #37474F;
  margin-left: 30px;
}
.modal-icon-style {
  color: #37474F;
  font-size: 45px;
}
.board-details {
  color: #37474F;
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  text-transform: capitalize;
}
.checkbox-div {
  padding-top: 15px;
  padding-bottom: 15px;
}
.my-checkbox {
  padding-left: 10px;
  font-size: 16px;
  color: #546E7A;
  font-weight: bold;
}
.running-status {
  float: right;
  color: #2E7D32;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}
input[type="checkbox"] {
accent-color: #FF3700
}
.plan-dates {
  margin-left: 10px;
  color: #37474F;
  font-size: 14px;
  font-weight: 400;
}
</style>
