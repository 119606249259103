<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    @click:outside="cancel()"
  >
    <v-card color="#CFD8DC">
      <v-toolbar
        color="#37474F"
        dark
      >
        <v-toolbar-title class="white--text">
          Media Files
        </v-toolbar-title>
      </v-toolbar>
      <v-card-actions>
        <v-btn
          class="mt-2"
          color="red darken-3"
          @click="cancel()"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          class="mt-2"
          color="#FF3700"
          :disabled="disableNext"
          @click="nextPage()"
        >
          Next
        </v-btn>
      </v-card-actions>
      <v-card-text class="text-body-1 text-center">
        <div class="no-file-alert">
          <h4 v-if="showNoFileAlert">
            No media files found. Please add contents to make use of it.
          </h4>
        </div>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              lg="6"
            >
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                outlined
                dense
              />
            </v-col>
            <v-col
              v-if="showMediaFolders"
              cols="12"
              md="5"
            >
              <v-autocomplete
                v-model="folderId"
                :items="mediaFolders"
                label="Select Folder"
                menu-props="auto"
                outlined
                dense
                item-text="name"
                item-value="id"
                @input="getFolderContents()"
              />
            </v-col>
            <v-col
              v-if="showMediaFolders"
              cols="12"
              md="1"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2"
                    v-bind="attrs"
                    fab
                    dark
                    color="#37474F"
                    small
                    v-on="on"
                    @click="getAllSavedContents()"
                  >
                    <v-icon
                      small
                      dark
                    >
                      mdi-file-multiple-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>ALL Files</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="(banner, n) in filteredSavedContents"
              :key="n"
              class="d-flex child-flex"
              :cols="columnToDisplay"
            >
              <v-hover v-slot:default="{ hover }">
                <v-badge
                  :value="showBadge(banner)"
                  color="green darken-2"
                  overlap
                  bordered
                  icon="mdi mdi-checkbox-marked-circle-outline"
                >
                  <a
                    @click="selectImg(banner)"
                  >
                    <v-card
                      :elevation="hover ? 12 : 2"
                      :class="{ 'on-hover': hover }"
                      color="#CFD8DC"
                      height="240px"
                    >
                      <div v-if="isContentImgOrGif(banner.content_type)">
                        <v-img
                          :src="getFilePath(banner.file_path)"
                          :lazy-src="getFilePath(banner.file_path)"
                          aspect-ratio="1"
                          width="100%"
                          height="120"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              />
                            </v-row>
                          </template>
                        </v-img>
                      </div>
                      <div v-else>
                        <video
                          width="100%"
                          height="120"
                          controls
                          contain
                        >
                          <source
                            :src="getFilePath(banner.file_path)"
                            type="video/mp4"
                          >
                        </video>
                      </div>
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                          color="#036358"
                        />
                      </v-fade-transition>
                      <br>
                      <div class="file-details">
                        <p class="my-p-style">
                          Name:-
                          <font
                            class="font-my-style"
                          >
                            {{ banner.original_file_name }}
                          </font>
                        </p>
                        <p class="my-p-style">
                          Dimension:-
                          <font
                            class="font-my-style"
                          >
                            {{ banner.dimension }}
                          </font>
                        </p>
                      </div>
                      <br>
                    </v-card>
                  </a>
                </v-badge>
              </v-hover>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { cdnUrl } from 'src/app-globals';
  import Constants from 'src/constants';
  import ModuleHelper from 'src/helpers/module-helper';
  import global from 'src/mixins/global';

  export default {
    name: 'BannerUploadList',
    mixins: [global],
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      selectedContents: [],
      search: '',
      folderId: '',
      helper: new ModuleHelper(),
    }),
    computed: {
      dialog: {
        get: function () {
          return this.value;
        },
        set: function (value) {
          this.$emit('input', value);
        },
      },
      savedContents () {
        return this.$store.getters['contents/getSavedContents'];
      },
      disableNext () {
        return this.selectedContents.length === 0;
      },
      anySelectedFiles () {
        return this.$store.getters['campaigns/getCampaignFiles'];
      },
      showNoFileAlert () {
        if (this.filteredSavedContents.length === 0 || this.savedContents === 0) {
          return true;
        }
        return false;
      },
      filteredSavedContents () {
        return this.savedContents.filter((file) => {
          return file.original_file_name.toUpperCase().match(this.search.toUpperCase());
        });
      },
      mediaFolders () {
        return this.$store.getters['mediafile/getMediaFolders'];
      },
      showMediaFolders () {
        if (this.helper.isSubModuleExist(Constants.MODULE_MEDIA_LIBRARY, Constants.SUB_MODULE_MEDIA_FOLDER) && this.permissionCheck('read-folder-list')) {
          return true;
        }
        return false;
      },
      columnToDisplay () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return 3;
        } else {
          return 6;
        }
      },
    },
    watch: {
      dialog (value) {
        if (value === false && this.anySelectedFiles.length <= 0) {
          this.selectedContents = [];
          this.search = '';
        }
      },
    },
    created () {
     this.permissionCheck('read-content') && this.fetchMediaFiles();
    },
    async mounted () {
      (this.permissionCheck('read-folder-list') && this.permissionCheck('read-content')) && await this.fetchMediaFolderList();
    },
    methods: {
      async fetchMediaFiles () {
        await this.$store.dispatch('contents/fetchSavedContents', {
          params: {
            categoryType: 'saved_content',
          },
        });
      },
      async fetchMediaFolderList () {
        await this.$store.dispatch('mediafile/fetchMediaFolders');
      },
      getFilePath (fileName) {
        return `${cdnUrl}/${fileName}`;
      },
      selectImg (banner) {
        // Removes the banner from the list if already selected.
        if (this.selectedContents.includes(banner)) {
          const index = this.selectedContents.indexOf(banner);
          this.$store.dispatch('campaigns/deleteSelectedFile', index);
          this.selectedContents.splice(index, 1);
          return;
        }
        this.selectedContents.push(banner);
      },
      showBadge (banner) {
        return this.selectedContents.includes(banner);
      },
      cancel () {
        if (this.anySelectedFiles.length > 0) {
          for (const files of this.anySelectedFiles) {
            if (files.formData.category_type === 'saved_content') {
              this.dialog = false;
              return;
            }
          }
        }
        this.selectedContents = [];
        this.dialog = false;
      },
      async nextPage () {
        for (const content of this.selectedContents) {
          const isFound = this.anySelectedFiles.some(item => item.formData.unique_file_name === content.unique_file_name);
          if (!isFound) {
            const digiBoardTimeSlots = [];
            this.$store.dispatch('campaigns/onSelectedContents', {
              formData: content,
              digiBoardTimeSlots: digiBoardTimeSlots,
            });
          }
        }
        this.dialog = false;
      },
      isContentImgOrGif (contentType) {
        if (contentType === 'image' || contentType === 'gif') {
          return true;
        }
        return false;
      },
      async getFolderContents () {
        await this.$store.dispatch('contents/fetchSelectedFolderSavedContents', {
          params: {
            categoryType: 'saved_content',
            folderId: this.folderId,
          },
        });
      },
      async getAllSavedContents () {
        this.folderId = '';
        await this.$store.dispatch('contents/fetchSavedContents', {
          params: {
            categoryType: 'saved_content',
          },
        });
      },
      removeBadge (index) {
        this.selectedContents.splice(index, 1);
      },
    },
  };
</script>
<style scoped>
  .v-card > .v-card-title {
    position: sticky;
    top: 0;
    z-index: 999;
  }
  .v-card > .v-card-actions {
    position: sticky;
    top: 0;
    z-index: 999;
  }
.my-p-style {
  font-size: 13.5px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.font-my-style {
  font-weight: bold;
  font-size: 12px;
  color: #37474F;
}
.file-details {
  text-align: left;
  margin-left: 10px;
}
.no-file-alert {
  color: #C62828;
}
</style>
